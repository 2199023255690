import React from "react";
import { graphql } from "gatsby";
import { PrivacyCard } from "../components/PageComps/CommonComps";
import StandardSEO from "../components/common/seo/StandardSEO";

const PrivacyNoticePage = ({ data }) => {
  const { sanityPrivacyNoticePage } = data;

  return <PrivacyCard data={sanityPrivacyNoticePage} />;
};

export default PrivacyNoticePage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityPrivacyNoticePage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityPrivacyNoticePage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      point: privacyPolicy {
        sectionTitle
        sectionPoints
      }
      updatedAt: _updatedAt
    }
  }
`;
